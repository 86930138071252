import React from 'react';
import logo from './logo.svg';
import Main from './Main.js'
import Terms from './Terms.js'
import About from './About.js'
import HowTo from './HowTo.js'
import './App.css';

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/how-to-play" element={<HowTo/>}/>
        <Route path="/*" element={<Main/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;



