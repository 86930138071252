import React from 'react';
import Main from './Main.js'
import './App.css';
import { Link } from "react-router-dom";


function HowTo(){

    return(
        <div className="container" style={{overflowY:"scroll"}}>
            <div style={{paddingBottom:"20px", flex:1, justifyContent:"center",alignItems:"center", display: "flex", flexDirection: "column", boxSizing: "border-box"}}>

                <div className="nicebox" style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"20px", fontSize:"1.5em", width:"60%", minWidth:"350px"}}>
                
                <h3>How to Play</h3>
                    <p>
                        At the start of the game, each player is secretly assigned a different murder suspect, location, and weapon that they must identify with the ghost's help. 
                        Over the course of the game, the ghost will send "visions" to each player in the form of images or animations, pointing to the suspect/location/weapon they want the player to select.
                        The game is played in rounds. During a round, the ghost will send one or more visions to each psychic.
                        After all psychics have made a guess, they are evaluated simultaneously. Successful psychics move on to the next category (i.e., location/weapon), 
                        while unsuccessful psychics continue to guess the same category.
                        In order for the group to succeed and set the ghost's spirit free, each player must successfully complete their murder narrative within 7 turns.
                        If any player is unsuccessful, the whole group loses!
                    </p>
                    <p>
                    <b>Psychic: </b> As a psychic, your job is to interpret visions sent by the ghost. 
                    In each round, the ghost can send one or more visions. 
                    Those visions all point to a single suspect/location/weapon. You will select an option to guess based on your intuition and consultation with your fellow psychics. 
                    </p>
                    <p>
                    <b>Ghost: </b> As the ghost, your job is to send visions to each psychic pointing to their highlighted suspect/location/weapon. 
                    After you have sent visions to all psychics, you must wait until they have all submitted guesses.
                    </p>
                </div>
                <br/>
                <Link style={{padding:"0px 15px", textDecoration:"none"}} to={"/"}> Return</Link> 

            </div>

        </div>
    )
}

export default HowTo;