import React from 'react';
import Main from './Main.js'
import './App.css';
import { Link } from "react-router-dom";


function About(){

    return(
        <div className="container" style={{overflowY:"scroll"}}>
            <div style={{flex:1, justifyContent:"center",alignItems:"center", display: "flex", flexDirection: "column", boxSizing: "border-box"}}>

                <div className="nicebox" style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"20px", fontSize:"1.5em", width:"60%", minWidth:"350px"}}>
                
                <h3>About</h3>
                <p>
                <b>Ghost in the Cell</b> (GitC) is a cooperative deduction game. 
                One player is a recently-deceased <b>ghost</b>, and the others are <b>psychics</b> trying to solve the mystery of the ghost's murder.
                The ghost has the ability to haunt the psychic's devices to send "visions" implicating the circumstances surrounding their demise.
                The players must work together to interpret the ghost's visions and uncover the truth.
                </p>
                <p>
                Ghost in the Cell is a web adaptation of the fantastic board game <a href="https://en.wikipedia.org/wiki/Mysterium_(board_game)">Mysterium</a> by <a href="https://www.libellud.com/?lang=en">Libellud</a>. 

                </p>
                </div>
                <br/>
                <Link style={{padding:"0px 15px", textDecoration:"none"}} to={"/"}> Return</Link> 

            </div>

        </div>
    )
}

export default About;