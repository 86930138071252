import React from 'react';
import SwiperCore, { Pagination, Zoom} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/zoom/zoom.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import 'swiper/components/pagination/pagination.scss';


SwiperCore.use([Pagination, Zoom]);

function StyledSwiper(props){
    return(
        <Swiper
            pagination={{ clickable: true }}
            zoom={{zoomedSlideClass:"swiper-slide-zoomed-a"}}
            style={{ width: '100%', flex:1, padding:"5px", zIndex:0}}
            spaceBetween={10}
            slidesPerView={1.25}
            centeredSlides={true}
            onSlideChange={props.onSlideChange}
            coverflowEffect= {{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
        >
            {props.children}
        </Swiper>
    )
}

export default StyledSwiper